import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PagesHero from "../components/pages"
import Section from "../components/section"
import Travel404 from "../images/travel404.svg"
import { colors } from "../utils/presets"

/** @jsx jsx */
import { jsx } from "@emotion/core"

export default props => (
  <Layout location={props.location}>
    <Seo title={`404`} />
    <PagesHero>
      <Section>
        <div
          className="columns"
          css={{
            padding: 70,
          }}
        >
          <div className="column has-text-right">
            <div className="section">
              <h1
                css={{
                  fontSize: 120,
                  fontWeight: 600,
                  color: colors.NAVYBLUE,
                }}
              >
                404
              </h1>
              <p>Sorry, we couldn't find that page</p>
            </div>
          </div>
          <div className="column">
            <img src={Travel404} width="404" alt="404" />
          </div>
        </div>
      </Section>
    </PagesHero>
  </Layout>
)
